// Commented temporarily  https://jira.tchdm.live/browse/SHOP-2220
// import { isReCaptchaValid, removeRecaptchaError, setRecaptchaError } from './ReCaptchaActions';

export const initProfileForm = () => {
    // VARS
    const formSelector = '[data-profile-form]';
    const form = document.querySelector(formSelector);
    if (!form) return;
    const profileInput = form.querySelector('[data-profile-input]');
    const profileInputWrap = form.querySelector('[data-profile-input-wrap]');
    const profileButton = form.querySelector('[data-profile-button]');
    const profileButtonUsername = form.querySelector('[data-profile-button-username]');
    const classLinkIcon = 'profile-input-wrap--link';
    const instagramDomain1 = 'https://instagram.com/';
    const instagramDomain2 = 'https://www.instagram.com/';
    const minLengthUserName = 1;
    const maxLengthUserName = 50;

    // INITS
    profileInput.value = '';

    // EVENTS
    form.addEventListener('submit', handleOnSubmit, false);

    profileInput.addEventListener('input', handleOnInput, false);
    profileInput.addEventListener('focus', handleOnFocus, false);
    profileInput.addEventListener('change', handleOnChange, false);

    // HANDLERS
    function handleOnSubmit(event) {
        validateForm();

        if (form.dataset.profileForm === 'no-valid') {
            event.preventDefault();
            form.dataset.profileForm = 'valid';
        }
    }

    function handleOnInput() {
        const value = this.value.trim();

        toggleSubmitButton(value);
        toggleIcon(value);
        setValueToButton(value);
    }

    function handleOnChange() {
        const value = this.value.trim();

        toggleSubmitButton(value);
        toggleIcon(value);
    }

    function handleOnFocus() {
        hideProfileInputError();
    }

    // FUNCTIONS
    function toggleSubmitButton(value) {
        value ? profileButton.removeAttribute('disabled') : profileButton.setAttribute('disabled', '');
    }

    function toggleIcon(value) {
        value.indexOf('https') === 0
            ? profileInputWrap.classList.add(classLinkIcon)
            : profileInputWrap.classList.remove(classLinkIcon);
    }

    function validateForm() {
        validateProfileInput();
        // Commented temporarily  https://jira.tchdm.live/browse/SHOP-2220
        // if (isReCaptchaValid()) {
        //     removeRecaptchaError(formSelector);
        // } else {
        //     setRecaptchaError(formSelector);
        //     form.dataset.profileForm = 'no-valid';
        // }
    }

    function validateProfileInput() {
        const inputValue = profileInput.value.trim();

        if (inputValue.length <= minLengthUserName) {
            form.dataset.profileForm = 'no-valid';
        }

        if (inputValue.indexOf('https') !== -1) {
            const accountLink = clearAccountLink(inputValue);

            if (accountLink.length <= instagramDomain1.length + minLengthUserName) {
                form.dataset.profileForm = 'no-valid';
            }

            if (accountLink.length >= instagramDomain1.length + maxLengthUserName) {
                form.dataset.profileForm = 'no-valid';
            }

            if (accountLink.indexOf(instagramDomain1) === -1 || accountLink.indexOf(instagramDomain1) !== 0) {
                form.dataset.profileForm = 'no-valid';
            }
        } else if (inputValue.length > maxLengthUserName) {
            form.dataset.profileForm = 'no-valid';
        }

        if (form.dataset.profileForm === 'no-valid') {
            showProfileInputError();
        }
    }

    function showProfileInputError() {
        profileInputWrap.classList.add('has-error');
    }

    function hideProfileInputError() {
        profileInputWrap.classList.remove('has-error');
    }

    function setValueToButton(value) {
        let currentValue = value;

        if (currentValue.indexOf(instagramDomain1) === 0 || currentValue.indexOf(instagramDomain2) === 0) {
            currentValue = clearAccountLink(value);
            currentValue = currentValue.replace(instagramDomain1, '');
        }

        if (currentValue.indexOf('@') === 0) {
            currentValue = currentValue.substring(1);
        }

        if (currentValue.indexOf('/') >= 0) {
            currentValue = currentValue.replaceAll('/', '');
        }

        profileButtonUsername.textContent = currentValue;
    }

    function clearAccountLink(link) {
        let clearedLink = link;

        if (clearedLink.indexOf('https://www.') !== -1) {
            clearedLink = clearedLink.replace('www.', '');
        }

        const accountFullLink = new URL(clearedLink);
        return accountFullLink.origin + accountFullLink.pathname;
    }
};
