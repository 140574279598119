export const initTabs = () => {
    // Vars
    const tabSets = document.querySelectorAll('[data-platforms-tabs]');
    const activeClass = 'is-active';

    // Listeners
    tabSets.forEach((tabSet) => {
        const tabNav = tabSet.querySelectorAll('[data-tab-name]');
        const tabContent = tabSet.querySelectorAll('[data-tab-content]');

        tabNav.forEach((item) => {
            item.addEventListener(
                'click',
                function () {
                    selectTabNav(item, tabNav);
                    selectTabContent(item.getAttribute('data-tab-name'), tabContent);
                },
                false,
            );
        });
    });

    // Handlers
    function selectTabNav(clickedTab, allTabs) {
        allTabs.forEach((item) => {
            item.classList.remove(activeClass);
        });
        clickedTab.classList.add(activeClass);
    }

    // Functions
    function selectTabContent(tabName, allTabsContent) {
        allTabsContent.forEach((item) => {
            item.classList.contains(tabName) ? item.classList.add(activeClass) : item.classList.remove(activeClass);
        });
    }
};
