import amplitude from 'amplitude-js';
import { eventProperties } from './amplitudeConfig';
import cloneDeep from 'lodash.clonedeep';

export const initAmplitudeOnChange = () => {
    const elements = document.querySelectorAll('[data-amplitude-change]');

    elements.forEach((element) => {
        element.addEventListener('change', function () {
            const event = this.dataset.amplitudeChange;
            const amplitudeProperties = cloneDeep(eventProperties);

            if (event === 'change_autopassword') {
                amplitudeProperties.autopassword = this.checked;
            }

            amplitude.getInstance().logEvent(event, amplitudeProperties);
        });
    });
};
