import anime from 'animejs/lib/anime.es.js';

export const initShowMoreContent = () => {
    // VARS
    const buttons = document.querySelectorAll('[data-showmore-content-button]');
    const hideButtons = document.querySelectorAll('[data-hide-content-button]');
    const duration = 300;
    const classVisible = 'is-visible';

    if (!buttons.length) return;

    // LISTENERS
    [...buttons].forEach((button) => {
        button.addEventListener('click', handleOnClickButton, false);
    });

    [...hideButtons].forEach((hideButton) => {
        hideButton.addEventListener('click', handleOnClickHideButton, false);
    });

    // HANDLERS
    function handleOnClickButton() {
        showMoreContent(this);
    }

    function handleOnClickHideButton() {
        hideContent(this);
    }

    // FUNCTIONS
    function showMoreContent(button) {
        const content = button.closest('[data-showmore-content-box]').querySelector('[data-showmore-content]');
        const height = content.scrollHeight;

        const hideButton = button.closest('[data-showmore-content-box]').querySelector('[data-hide-content-button]');

        button.style.display = 'none';
        hideButton.style.display = 'inline-block';

        // Set initial height explicitly
        content.style.height = '0';

        anime({
            targets: content,
            height: [0, height],
            easing: 'linear',
            duration: duration,
            complete: function () {
                content.style.height = 'auto';
                content.classList.add(classVisible);
            },
        });
    }

    function hideContent(hideButton) {
        const content = hideButton.closest('[data-showmore-content-box]').querySelector('[data-showmore-content]');
        const showButton = hideButton
            .closest('[data-showmore-content-box]')
            .querySelector('[data-showmore-content-button]');

        hideButton.style.display = 'none';
        showButton.style.display = 'inline-block';
        content.style.height = content.scrollHeight + 'px';

        anime({
            targets: content,
            height: [content.scrollHeight, 0],
            easing: 'linear',
            duration: duration,
            complete: function () {
                content.style.height = '0';
                content.classList.remove(classVisible);
            },
        });
    }
};
