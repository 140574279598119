import { initProfileForm } from './forms/initProfileForm';
import { initFunnelForm } from './forms/initFunnelForm';
import { initToggleMobilePriceCards } from './forms/initToggleMobilePriceCards';
import { initAppendHiddenInputsToPlanForms } from '../../../../../Common/Resources/src_front/js/components/initAppendHiddenInputsToPlanForms';

export const initForms = () => {
    initProfileForm();
    initFunnelForm();
    initToggleMobilePriceCards();
    initAppendHiddenInputsToPlanForms();
};
