class PageLoader {
    constructor() {
        this.loader = document.querySelector('[data-page-loader]');
        this.hiddenClass = 'page-loader--hidden';

        this.hideWithTimeout(1000);

        window.addEventListener('pageshow', (event) => {
            if (event.persisted) {
                window.location.reload();
            }
        });
    }

    show() {
        this.loader?.classList.remove(this.hiddenClass);
    }

    hide() {
        this.loader?.classList.add(this.hiddenClass);
    }

    hideWithTimeout(timeout = 1000) {
        setTimeout(() => {
            if (!this.loader.classList.contains(this.hiddenClass)) {
                this.hide();
            }
        }, timeout);
    }
}

window.pageLoader = new PageLoader();
