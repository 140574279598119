import amplitude from 'amplitude-js';
import cloneDeep from 'lodash.clonedeep';
import { eventProperties } from './amplitudeConfig';

export const initAmplitudeOnClick = () => {
    const elements = document.querySelectorAll('[data-amplitude-click]');

    elements.forEach((element) => {
        element.addEventListener('click', function () {
            const event = this.dataset.amplitudeClick;
            const amplitudeProperties = cloneDeep(eventProperties);

            if (this.hasAttribute('data-page-location')) {
                amplitudeProperties.page_location = this.getAttribute('data-page-location');
            }

            if (this.hasAttribute('data-amplitude-click-feature-name')) {
                amplitudeProperties.features_slider_title = this.getAttribute('data-amplitude-click-feature-name');
            }

            if (event === 'click_next_funnel') {
                const input = document.querySelector('[name="type-of-account"]:checked');
                amplitudeProperties.selected_account_type = input.dataset.amplitudeChange;
            }

            if (event === 'click_sign_up_funnel') {
                const email = document.querySelector('[data-amplitude-focus="click_type_email"]');
                const autoPassword = document.querySelector('[data-amplitude-change="change_autopassword"]');
                amplitudeProperties.user_email = email.value.trim();
                amplitudeProperties.autopassword_status = autoPassword.checked ? 1 : 0;
            }

            if (event === 'click_google_api' || event === 'click_facebook_api') {
                const autoPassword = document.querySelector('[data-amplitude-change="change_autopassword"]');
                amplitudeProperties.autopassword_status = autoPassword.checked ? 1 : 0;
            }

            amplitude.getInstance().logEvent(event, amplitudeProperties);
        });
    });
};
