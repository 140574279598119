import { isValidMail } from '../../../../../../Common/Resources/src_front/js/helper-functions/isValidMail';
import amplitude from 'amplitude-js';

export const initFunnelForm = () => {
    // VARS
    const form = document.querySelector('[data-funnel-form]');
    if (!form) return;
    const userEmail = form.querySelector('[data-funnel-form-email]');
    const userPassword = form.querySelector('[data-funnel-form-password]');
    const userPasswordLabel = form.querySelector('[data-funnel-form-password-label]');
    const agreeCheckbox = form.querySelector('[data-funnel-form-agree]');
    const passwordCheckbox = form.querySelector('[data-funnel-password-checkbox]');
    const fieldsSelector = {
        userEmail: '[data-funnel-form-email-wrap]',
        userPassword: '[data-funnel-form-password-wrap]',
        agreeCheckbox: '[data-funnel-form-agree-wrap]',
    };
    const buttonShowPassword = form.querySelector('[data-funnel-form-show-password]');
    const buttonHidePassword = form.querySelector('[data-funnel-form-hide-password]');

    const errorClass = 'is-error';
    let isValidForm = true;

    // LISTENERS
    form.addEventListener('submit', handlerOnSubmit, false);

    userEmail.addEventListener(
        'focus',
        function () {
            hideErrorMessage(fieldsSelector.userEmail);
        },
        false,
    );

    userPassword.addEventListener(
        'focus',
        function () {
            hideErrorMessage(fieldsSelector.userPassword);
        },
        false,
    );

    passwordCheckbox.addEventListener(
        'change',
        function () {
            togglePasswordField(this.checked);
            hideErrorMessage(fieldsSelector.userPassword);
        },
        false,
    );

    agreeCheckbox.addEventListener(
        'change',
        function () {
            this.checked
                ? hideErrorMessage(fieldsSelector.agreeCheckbox)
                : showErrorMessage(fieldsSelector.agreeCheckbox, 'required');
        },
        false,
    );

    buttonShowPassword.addEventListener(
        'click',
        function () {
            userPassword.type = 'text';
            this.setAttribute('hidden', '');
            buttonHidePassword.removeAttribute('hidden');
            buttonHidePassword.focus();
        },
        false,
    );

    buttonHidePassword.addEventListener(
        'click',
        function () {
            userPassword.type = 'password';
            this.setAttribute('hidden', '');
            buttonShowPassword.removeAttribute('hidden');
            buttonShowPassword.focus();
        },
        false,
    );

    // HANDLES
    function handlerOnSubmit(event) {
        validateUserEmail();
        validateAgreeCheckbox();

        if (!passwordCheckbox.checked) {
            validatePasswordCheckbox();
        }

        if (!isValidForm) {
            event.preventDefault();
            isValidForm = true;
        } else {
            const identify = new amplitude.Identify().set('email', userEmail.value.trim());
            amplitude.getInstance().identify(identify);
        }
    }

    // FUNCTIONS
    function togglePasswordField(isChecked) {
        if (isChecked) {
            userPasswordLabel.setAttribute('hidden', '');
        } else {
            userPasswordLabel.removeAttribute('hidden');
        }
    }

    function validateUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);

        if (!userEmail.value.trim()) {
            showErrorMessage(fieldsSelector.userEmail, 'required');
            isValidForm = false;
        } else if (!isValidMail(userEmail.value.trim())) {
            showErrorMessage(fieldsSelector.userEmail, 'invalid');
            isValidForm = false;
        } else {
            hideErrorMessage(fieldsSelector.userEmail);
        }
    }

    function validatePasswordCheckbox() {
        hideErrorMessage(fieldsSelector.userPassword);

        if (userPassword.value.trim().length < 6) {
            showErrorMessage(fieldsSelector.userPassword, 'invalid');
            isValidForm = false;
        } else {
            hideErrorMessage(fieldsSelector.userPassword);
        }
    }

    function validateAgreeCheckbox() {
        hideErrorMessage(fieldsSelector.agreeCheckbox);

        if (!agreeCheckbox.checked) {
            showErrorMessage(fieldsSelector.agreeCheckbox, 'required');
            isValidForm = false;
        } else {
            hideErrorMessage(fieldsSelector.agreeCheckbox);
        }
    }

    function showErrorMessage(selector, type) {
        const fieldWrap = form.querySelector(selector);
        const error = fieldWrap.querySelector(`[data-funnel-form-error="${type}"]`);

        fieldWrap.classList.add(errorClass);
        error.classList.add(errorClass);
    }

    function hideErrorMessage(selector) {
        const fieldWrap = document.querySelector(selector);
        const errors = fieldWrap.querySelectorAll('[data-funnel-form-error]');

        fieldWrap.classList.remove(errorClass);
        [...errors].forEach((error) => {
            error.classList.remove(errorClass);
        });
    }
};
