import HystModal from 'hystmodal';
import Swiper from 'swiper/bundle';

const initializedSliders = [];

export const initStepsModal = () => {
    const modal = new HystModal({
        linkAttributeName: 'data-hystmodal',
        beforeOpen: (modal) => {
            const sliderSelector = modal.starter.getAttribute('data-slider');

            if (!initializedSliders.includes(sliderSelector)) {
                const mySwiper = new Swiper(sliderSelector, {
                    loop: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });

                initializedSliders.push(sliderSelector);
            }
        },
    });
};
