import amplitude from 'amplitude-js';
import { eventProperties } from './amplitudeConfig';
import cloneDeep from 'lodash.clonedeep';

export const initAmplitudeOnFocus = () => {
    const elements = document.querySelectorAll('[data-amplitude-focus]');

    elements.forEach((element) => {
        element.addEventListener('focus', function () {
            const event = this.dataset.amplitudeFocus;
            const amplitudeProperties = cloneDeep(eventProperties);

            amplitude.getInstance().logEvent(event, amplitudeProperties);
        });
    });
};
