export const initToggleMobilePriceCards = () => {
    // VARS
    const priceCards = document.querySelectorAll('[data-mobile-price-card]');
    const mobilePriceForms = document.querySelectorAll('[data-mobile-price-form]');
    if (!priceCards.length || !mobilePriceForms.length) return;
    const activeClass = 'price-card--active';
    const breakPoint = 768;

    // LISTENERS
    [...priceCards].forEach((card) => {
        card.addEventListener('click', handleOnClickCard, false);
    });

    // HANDLERS
    function handleOnClickCard() {
        if (window.innerWidth >= breakPoint) return;
        toggleActiveCard(this);
        toggleActiveForm(this);
    }

    // FUNCTIONS
    function toggleActiveCard(currentCard) {
        const activeCard = document.querySelector(`.${activeClass}`);

        activeCard.classList.remove(activeClass);
        currentCard.classList.add(activeClass);
    }

    function toggleActiveForm(currentCard) {
        const index = currentCard.dataset.mobilePriceCard;
        const mobilePriceForm = document.querySelector(`[data-mobile-price-form="${index}"]`);

        [...mobilePriceForms].forEach((form) => {
            form.setAttribute('hidden', '');
        });

        mobilePriceForm.removeAttribute('hidden');
    }
};
