import { closeAllResultWindow } from '../initSearch';
import { getTarget } from '../helper-function/get-target';

export const initAccountSearchModal = () => {
    // VARS
    const inputs = document.querySelectorAll('[data-search-account-form-input]');
    const buttonsOpenMobileForm = document.querySelectorAll('[data-button-open-mobile-search-form]');
    const closeButtons = document.querySelectorAll('[data-search-account-form-close-modal-button]');
    const formsWrappers = document.querySelectorAll('[data-search-account-form-wrap]');
    const openModalClass = 'is-open-modal';

    // LISTENERS
    [...inputs].forEach((input) => {
        input.addEventListener('focus', handleOnFocusInput, false);
    });

    [...buttonsOpenMobileForm].forEach((button) => {
        button.addEventListener('click', handleOnClickButtonOpenMobileForm, false);
    });

    [...closeButtons].forEach((button) => {
        button.addEventListener('click', handleOnClickCloseButton, false);
    });

    window.addEventListener(
        'resize',
        function () {
            [...formsWrappers].forEach((wrap) => {
                if (wrap.classList.contains(openModalClass) && window.innerWidth < 600) {
                    disableScroll();
                } else if (wrap.classList.contains(openModalClass) && window.innerWidth >= 600) {
                    enableScroll();
                }
            });
        },
        false,
    );

    document.addEventListener(
        'click',
        function (event) {
            if (!getTarget(event, '[data-search-account-form-wrap]') && window.innerWidth >= 600) {
                closeAllResultWindow();
            }
        },
        false,
    );

    // HANDLERS
    function handleOnFocusInput() {
        toggleModalForm(this);
    }

    function handleOnClickButtonOpenMobileForm() {
        toggleModalForm(this);
    }

    function handleOnClickCloseButton() {
        const wrap = this.closest('[data-search-account-form-wrap]');

        closeModalForm(wrap);
        closeAllResultWindow();
        enableScroll();
    }

    // FUNCTIONS
    function toggleModalForm(input) {
        const wrap = input.closest('[data-search-account-form-wrap]');

        if (!wrap.classList.contains(openModalClass)) {
            openModalForm(wrap);

            if (window.innerWidth < 600) {
                disableScroll();
            }
        }
    }

    function closeModalForm(wrap) {
        wrap.classList.remove(openModalClass);
    }

    function openModalForm(wrap) {
        const input = wrap.querySelector('[data-search-account-form-input]');

        wrap.classList.add(openModalClass);
        input.focus();
    }
};
