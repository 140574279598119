import { getTarget } from './helper-function/get-target';
import { debounceEvent } from './helper-function/debounceEvent';
import { removeAllChildNodes } from './helper-function/removeAllChildNodes';

export const initSearch = () => {
    // VARS
    const forms = document.querySelectorAll('[data-search-account-form]');
    if (!forms.length) return;
    const inputs = document.querySelectorAll('[data-search-account-form-input]');
    const resetButtons = document.querySelectorAll('[data-search-account-form-reset-button]');
    const debouncedHandleOnEnter = debounceEvent(handleOnEnterValue, 1000);
    const activeClass = 'is-visible';
    const urlSignUp = forms[0].dataset.searchAccountForm;

    // LISTENERS
    [...forms].forEach((form) => {
        form.addEventListener('submit', handleOnsSubmitForm, false);
    });

    [...inputs].forEach((input) => {
        input.addEventListener('input', debouncedHandleOnEnter);
    });

    [...inputs].forEach((input) => {
        input.addEventListener('past', debouncedHandleOnEnter);
    });

    [...inputs].forEach((input) => {
        input.addEventListener('focus', handleOnFocusInput);
    });

    [...resetButtons].forEach((button) => {
        button.addEventListener('click', handleOnClickButtonResetForm, false);
    });

    document.addEventListener(
        'click',
        function (event) {
            if (getTarget(event, '[data-account]')) {
                const user = getTarget(event, '[data-account]');
                const userName = user.querySelector('[data-user-name]').textContent.trim();
                const form = user
                    .closest('[data-search-account-form-wrap]')
                    .querySelector('[data-search-account-form]');
                const input = form.querySelector('[data-search-account-form-input]');

                input.value = userName;
                closeAllResultWindow();
            }
        },
        false,
    );

    // HANDLERS
    async function handleOnEnterValue() {
        toggleResetButton(this);

        if (this.value.trim()) {
            await fetchAccountUsers(this);
        } else {
            closeAllResultWindow();
        }
    }

    function handleOnClickButtonResetForm() {
        this.classList.remove(activeClass);
        closeAllResultWindow();
    }

    function handleOnsSubmitForm(event) {
        event.preventDefault();
        validateForm(this);
    }

    function handleOnFocusInput() {
        const form = this.closest('[data-search-account-form]');
        hideErrors(form);
    }

    // FUNCTIONS
    async function fetchAccountUsers(input) {
        const wrap = input.closest('[data-search-account-form-wrap]');
        if (!wrap.classList.contains('is-open-modal')) return;
        const resultBlock = wrap.querySelector('[data-result]');
        const resultListBlock = resultBlock.querySelector('[data-result-list]');
        const userName = getUserName(input);
        const url = 'account-search?username=' + userName;
        // const url = '/glassagram/insta.json'; // For test

        toggleEmptyResult(input, false);
        removeAllChildNodes(resultListBlock);
        openResultWindow(resultBlock);

        const response = await fetch(url);
        let accounts;

        if (response.ok) {
            accounts = await response.json();
        } else {
            renderAccountList(resultBlock, [], input);
        }

        hideSearchLoader(resultBlock);

        if (accounts) {
            renderAccountList(resultBlock, accounts, input);
        }
    }

    function renderAccountList(resultBlock, accounts, input) {
        if (accounts.length) {
            showAccountList(resultBlock, accounts);
        } else {
            toggleEmptyResult(input, true);
        }
    }

    function openResultWindow(resultBlock) {
        showSearchLoader(resultBlock);
        resultBlock.classList.add('is-visible');
    }

    function showAccountList(resultBlock, accounts) {
        const resultList = resultBlock.querySelector('[data-result-list]');

        accounts.forEach((account) => {
            const accountBlock = `
             <div class="search-account-form__result-user-card user-card" 
                  data-account
             >
                <img src="${account.url}"
                     class="user-card__image"
                     crossorigin="anonymous"
                     alt=""
                >
                <div class="user-card__info">
                    <p class="user-card__nickname" data-user-name>
                        ${account.username}
                    </p>
                    <p class="user-card__full-name">
                        ${account.name}
                    </p>
                </div>
             </div>
            `;

            resultList.insertAdjacentHTML('beforeend', accountBlock);
        });
    }

    function toggleEmptyResult(input, show = false) {
        const resultBlock = input.closest('[data-search-account-form-wrap]').querySelector('[data-result]');
        const emptyResultBlock = resultBlock.querySelector('[data-result-empty]');

        show ? emptyResultBlock.classList.add('is-visible') : emptyResultBlock.classList.remove('is-visible');
    }

    function hideSearchLoader(resultBlock) {
        const loader = resultBlock.querySelector('[data-result-loader]');
        loader.classList.add('is-hidden');
    }

    function showSearchLoader(resultBlock) {
        const loader = resultBlock.querySelector('[data-result-loader]');
        loader.classList.remove('is-hidden');
    }

    function toggleResetButton(input) {
        const form = input.closest('[data-search-account-form]');
        const resetButton = form.querySelector('[data-search-account-form-reset-button]');

        input.value.trim() ? resetButton.classList.add(activeClass) : resetButton.classList.remove(activeClass);
    }

    function getUserName(input) {
        const value = input.value.trim();
        let userName = value;

        if (value.indexOf('https://www.instagram.com') !== -1 && value.length > 27) {
            userName = getUserNameFromURL(value);
        }

        return userName;
    }

    function getUserNameFromURL(url) {
        const currentUrl = new URL(url);
        return currentUrl.pathname.replaceAll('/', '');
    }

    function validateForm(form) {
        const input = form.querySelector('[data-search-account-form-input]');
        const value = input.value.trim();

        if (!value.length) {
            closeAllResultWindow();
            showError(form, 'required');
        } else if (value.indexOf('https://www.instagram.com') === 0 && value.length < 28) {
            closeAllResultWindow();
            showError(form, 'account-link');
        } else {
            window.location.href = urlSignUp;
        }
    }

    function showError(form, typeError) {
        const currentError = form.querySelector(`[data-search-account-form-error="${typeError}"]`);

        hideErrors(form);
        currentError.removeAttribute('hidden');
    }

    function hideErrors(form) {
        const errors = form.querySelectorAll('[data-search-account-form-error]');

        [...errors].forEach((error) => {
            error.setAttribute('hidden', '');
        });
    }
};

export function closeAllResultWindow() {
    const resultBlocks = document.querySelectorAll('[data-result]');

    [...resultBlocks].forEach((resultBlock) => {
        resultBlock.classList.remove('is-visible');
    });
}
