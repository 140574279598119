import amplitude from 'amplitude-js';
import { eventProperties } from './amplitudeConfig';
import cloneDeep from 'lodash.clonedeep';

export const initAmplitudeOnSubmit = () => {
    const forms = document.querySelectorAll('[data-amplitude-form-submit]');

    forms.forEach((form) => {
        form.addEventListener('submit', function () {
            const event = this.dataset.amplitudeFormSubmit;
            const amplitudeProperties = cloneDeep(eventProperties);
            const errorsEmailForm = this.querySelectorAll('[data-funnel-form-error].is-error');
            const errorProfileForm = this.querySelector('[data-profile-input-wrap].has-error');
            const recaptchaError = this.querySelector('[data-recaptcha-error].not-valid');

            if (errorProfileForm || recaptchaError) {
                const username = this.querySelector('[data-profile-button-username]');
                this.dataset.validationErrorCount = `${+this.dataset.validationErrorCount + 1}`;
                amplitudeProperties.user_name = username.textContent.trim();
                amplitudeProperties.validation_error = '';

                if (errorProfileForm) {
                    amplitudeProperties.validation_error += `, ${errorProfileForm.textContent.trim()}`;
                }

                if (recaptchaError) {
                    amplitudeProperties.validation_error += `, ${recaptchaError.textContent.trim()}`;
                }

                amplitudeProperties.validation_error_count = this.dataset.validationErrorCount;
            }

            if (errorsEmailForm.length) {
                this.dataset.validationErrorCount = `${+this.dataset.validationErrorCount + 1}`;
                amplitudeProperties.validation_error = '';

                errorsEmailForm.forEach((error) => {
                    amplitudeProperties.validation_error += `${error.textContent.trim()}, `;
                    amplitudeProperties.validation_error_count = this.dataset.validationErrorCount;
                });
            }

            if (event === 'create_account') {
                const passwordCheckbox = this.querySelector('[data-funnel-password-checkbox]');
                amplitudeProperties.autopassword = passwordCheckbox.checked;
            }

            if (event === 'watch_username') {
                const inputName = this.querySelector('[data-profile-input]');
                amplitudeProperties.user_name = inputName.value.trim();
            }

            amplitude.getInstance().logEvent(event, amplitudeProperties);
        });
    });
};
