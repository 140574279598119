import './components/PageLoader';
import { initScrollLocker } from './assets/initScrollLocker';
import { initAmplitude } from './components/initAmplitude';
import { MobileMenu } from './components/MobileMenu';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { scrollTo } from './components/helper-function/scrollTo';
import { initSliders } from './components/initSliders';
import { initAccountSearchModal } from './components/modal/initAccountSearchModal';
import { initStopAnimateOnResize } from './assets/initStopAnimateOnResize';
import { initAccordion } from './components/initAccordion';
import { initForms } from './components/initForms';
import { initDeclineModal } from './components/modal/initDeclineModal';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initShowMoreContent } from './components/initShowMoreContent';
import { initTabs } from './components/initTabs';
import { initStepsModal } from './components/initStepsModal';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';

window.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

function handleOnLoadPage() {
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initHashEmail();
    initScrollLocker();
    window.mobileMenu = new MobileMenu();
    initSliders();
    scrollTo();
    initScrollToAnchor();
    initAccountSearchModal();
    initAccordion();
    initStopAnimateOnResize();
    initDeclineModal();
    initForms();
    initAmplitude();
    initShowMoreContent();
    initTabs();
    initStepsModal();
}
